import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Container, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  dirtyWhite,
  darkGreen,
  slateGrey,
  militaryGreen,
} from '../constants/colors'
import Card from '../components/card'
import peterImg from '../images/Peter.jpg'
import bartImg from '../images/Bart.jpg'
import krisImg from '../images/Kris.jpg'
import teamImg from '../images/team.jpg'
import backgroundImg from '../images/y.jpg'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    padding: 10,
  },
  title: {
    fontSize: 48,
    padding: '10px',
    margin: '50px 0px',
    fontWeight: 300,
    width: '100%',
    textAlign: 'center',
  },
  subtitle: {
    fontSize: 28,
    padding: '10px',
    fontWeight: 300,
    width: '100%',
    textAlign: 'center',
    color: slateGrey,
    fontWeight: 400,
    border: `6px solid slategrey`,
    backgroundColor: '#f1f1f1',
  },
  paragraphContainer: {
    padding: 20,
    backgroundColor: slateGrey,
    color: 'white',
    boxShadow: `2px 2px 4px 2px slategrey`,
  },
  paragraph: {
    width: '100%',
    textAlign: 'center',
    marginTop: 40,
    marginBottom: 40,
    fontWeight: 300,
  },
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  teamPhotosContainer: {
    marginTop: 20,
    backgroundColor: dirtyWhite,
    paddingTop: 30,
    paddingBottom: 30,
  },
  photo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
  },
  groupPhotoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  groupPhoto: {
    width: '90%',
    height: 'auto',
    boxShadow: `1px 0px 2px 1px silver`,
  },
}))
const Gallery = () => {
  const classes = useStyles()
  const subtitle = `Committed to Your Satisfaction !`
  const paragraphOne = `We are the proud owners of 4NB Design & Build with over a dozen years of professional hands on experience in the field of landscape and construction.`
  const paragraphTwo = `With our combined work experience and education  in landscape architecture, we are able to properly assess your individual preferences in order to create an appropriate design to meet your needs.`
  const paragraphThree = `Once you are completely satisfied with our proposed design, we transform your vision into reality using quality materials and top building standards. In doing so we provide you with high quality craftsmanship and results.`
  const paragraphs = [paragraphOne, paragraphTwo, paragraphThree]
  const teamPhotos = [
    {
      image: peterImg,
      name: 'Peter',
    },
    {
      image: bartImg,
      name: 'Bart',
    },
    {
      image: krisImg,
      name: 'Kris',
    },
  ]
  return (
    <Layout backImg={backgroundImg} headerTitle="PEOPLE BEHIND THE WORK">
      <SEO title="About" />
      <Container
        style={{ backgroundColor: 'white', padding: 40 }}
        maxWidth="lg"
      >
        <div>
          <h1 className={classes.title}>
            About{' '}
            <span
              style={{
                color: slateGrey,
                borderBottom: `1px solid ${slateGrey}`,
                fontWeight: 400,
              }}
            >
              4NB Design & Build
            </span>
          </h1>
        </div>
        <Grid container justify="space-between">
          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            className={classes.groupPhotoContainer}
          >
            <img src={teamImg} className={classes.groupPhoto} />
          </Grid>
          <Grid item md={5} className={classes.paragraphContainer}>
            <p className={classes.subtitle}>{subtitle}</p>
            {paragraphs.map(el => (
              <p className={classes.paragraph}>{el}</p>
            ))}
          </Grid>
        </Grid>
      </Container>
      <div style={{ marginTop: 30 }}>
        <h1 className={classes.title}>
          <span
            style={{ color: slateGrey, borderBottom: `1px solid ${slateGrey}` }}
          >
            MEET OUR TEAM
          </span>
        </h1>
      </div>
      <Grid
        container
        justify="space-evenly"
        className={classes.teamPhotosContainer}
      >
        {teamPhotos.map(image => (
          <Grid
            item
            xs={12}
            sm={5}
            md={4}
            key={image.name}
            className={classes.photo}
          >
            <Card name={image.name} img={image.image} />
          </Grid>
        ))}
      </Grid>
    </Layout>
  )
}

export default Gallery
